import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';

import Heading2 from '../../ui/Heading2';
import Heading3 from '../../ui/Heading3';
import { ReactComponent as ArrowRightIco } from '../../images/icons/arrow-right.svg';
import { BREAKPOINTS, COLORS } from '../../ui/variables';
import Button from '../../ui/BaseButton';
import { Subtitle } from '../../ui/TypographyStyles';
import ParagraphMedium from '../../ui/ParagraphMedium';
import ParagraphLarge from '../../ui/ParagraphLarge';

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${BREAKPOINTS.MOBILE} {
    flex-direction: column;
    align-items: center;
  }
`;

const Descriptor = styled.div`
  max-width: 488px;

  @media ${BREAKPOINTS.TABLET} {
    max-width: 369px;
  }

  @media ${BREAKPOINTS.MOBILE} {
    max-width: 517px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 80px;
  }
`;

const Features = styled.div`
  max-width: 540px;
  margin-left: 40px;

  @media ${BREAKPOINTS.TABLET} {
    max-width: 410px;
  }

  @media ${BREAKPOINTS.MOBILE} {
    max-width: none;
    margin-left: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

const Feature = styled.div`
  margin-bottom: 80px;

  @media ${BREAKPOINTS.MOBILE} {
    width: 48%;
    min-width: 340px;
    flex-shrink: 0;
  }
`;

const LearnArrow = styled(ArrowRightIco)`
  fill: ${COLORS.ORANGE};
`;

const FeatureImage = styled(Img)`
  max-width: 216px;
  margin-bottom: 32px;
  width: 100%;

  @media ${BREAKPOINTS.TABLET} {
    max-width: 164px;
  }

  @media ${BREAKPOINTS.MOBILE} {
    max-width: 141px;
  }
`;

interface Props {
  service: {
    navLink: string;
    name: string;
    description: string;
    featureImage: {
      fluid: FluidObject;
      title: string;
    };
    features: {
      title: string;
      description: string;
      url: string;
    }[];
  };
}

const Service = ({ service }: Props) => {
  return (
    <Container id={service.navLink}>
      <Descriptor>
        <FeatureImage
          fluid={service.featureImage.fluid}
          alt={service.featureImage.title}
        />
        <Heading2>{service.name}</Heading2>
        <ParagraphLarge
          css={css`
            margin-bottom: 48px;
          `}
        >
          {service.description}
        </ParagraphLarge>
        <Button
          css={css`
            padding: 16px 32px;
          `}
          href={`${process.env.GATSBY_NEW_ONBOARDING_URL}signup`}
          type='external'
          color={COLORS.ORANGE}
        >
          Book Now
        </Button>
      </Descriptor>

      <Features>
        {service.features.map(feature => (
          <Feature key={feature.title}>
            <Heading3>{feature.title}</Heading3>
            <ParagraphMedium>{feature.description}</ParagraphMedium>
            <Link
              css={css`
                display: block;
              `}
              to={feature.url}
            >
              Learn more <LearnArrow width='14' height='11' />
            </Link>
          </Feature>
        ))}
      </Features>
    </Container>
  );
};

export default Service;
