import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';

import { ServiceCategoriesQuery } from '../../../graphql-types';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Service from '../../components/services/Service';
import FeatureImages from '../../components/services/FeatureImages';

import Heading1 from '../../ui/Heading1';
import ParagraphExLarge from '../../ui/ParagraphExLarge';
import Heading2 from '../../ui/Heading2';
import { BREAKPOINTS, COLORS } from '../../ui/variables';
import { Subtitle, TitleMedium, Uppercase } from '../../ui/TypographyStyles';

const Container = styled.div`
  padding: 0 40px;
`;

const Heading = styled.div`
  max-width: 600px;
  margin: auto;
  text-align: center;
  padding: 100px 0;
`;

const Content = styled.div`
  max-width: 1240px;
  margin: auto;
`;

const DontSee = styled.div`
  background-color: ${COLORS.ORANGE};
  height: 582px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Navigation = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 126px;
  height: 80px;
  font-size: 20px;
  overflow-x: scroll;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;

  @media ${BREAKPOINTS.MOBILE} {
    height: 64px;
    max-width: none;
    padding: 0;
  }

  > ul {
    display: flex;
    justify-content: space-between;
    min-width: 430px;
    list-style: none;
    margin: 0;
    align-items: center;

    @media ${BREAKPOINTS.MOBILE} {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  a {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 120%;
    padding-left: 24px;
    color: ${COLORS.BLACK};

    &:hover,
    &:active {
      color: ${COLORS.ORANGE};
    }
  }
`;

export const pageQuery = graphql`
  query ServiceCategories {
    contentfulServicePage {
      categories {
        serviceCategory
        title
        description {
          description
        }
        services {
          description {
            description
          }
          urlSlug
          title
        }
        graphicFeature {
          title
          fluid(maxWidth: 220) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;

interface IServiceCategoriesProps {
  data: ServiceCategoriesQuery;
}

const Services = ({ data }: IServiceCategoriesProps) => {
  const categories = data.contentfulServicePage.categories;

  const formatted = categories.map(category => ({
    navLink: category.serviceCategory,
    name: category.title,
    description: category.description.description,
    featureImage: {
      fluid: category.graphicFeature.fluid,
      title: category.graphicFeature.title,
    },
    features: category.services.map(service => ({
      title: service.title,
      description: service.description.description,
      url: service.urlSlug,
    })),
  }));

  return (
    <Layout>
      <SEO
        title='Veterinary Services in New York, NY'
        description='Small Door Veterinary offers a full suite of veterinary services, including walk-ins and emergencies, with state-of-the-art equipment and modern facilities.'
      />
      <Heading>
        <Heading1>Expert veterinary services</Heading1>
        <ParagraphExLarge>
          We offer a full range of pet health services, plus priority access to
          board-certified specialists.
        </ParagraphExLarge>
      </Heading>

      <FeatureImages />

      <div
        css={css`
          padding: 0 40px;
        `}
      >
        <Navigation>
          <Uppercase
            css={css`
              color: ${COLORS.ORANGE};
              @media ${BREAKPOINTS.MOBILE} {
                display: none;
              }
            `}
          >
            Our services
          </Uppercase>
          <ul>
            {formatted.map(service => (
              <li key={service.name}>
                <a href={`#${service.navLink}`}>{service.navLink}</a>
              </li>
            ))}
          </ul>
        </Navigation>
      </div>

      <Container>
        <Content>
          {formatted.map(service => (
            <Service key={service.name} service={service} />
          ))}
        </Content>
      </Container>

      <DontSee>
        <div
          css={css`
            max-width: 595px;
            @media ${BREAKPOINTS.TABLET} {
              max-width: 615px;
            }
          `}
        >
          <Heading2
            css={css`
              color: ${COLORS.WHITE};
              margin: 0;
            `}
          >
            Don't see what{' '}
            <br
              css={css`
                display: none;
                @media (max-width: 500px) {
                  display: block;
                }
              `}
            />{' '}
            you need?
          </Heading2>
          <TitleMedium
            css={css`
              color: ${COLORS.WHITE};
              margin-bottom: 40px;
            `}
          >
            Chances are we do it.
          </TitleMedium>
          <Subtitle
            css={css`
              color: ${COLORS.WHITE};
            `}
          >
            Give us a call at{' '}
            <a
              css={css`
                color: ${COLORS.WHITE};
                font-weight: 700;
              `}
              href='tel:+1 (212) 933-9044'
            >
              (212) 933-9044
            </a>
          </Subtitle>
        </div>
      </DontSee>
    </Layout>
  );
};

export default Services;
