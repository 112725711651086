import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

import { BREAKPOINTS, COLORS } from "../../ui/variables";

const Container = styled.div`
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
  overflow: hidden;
  justify-content: center;

  @media ${BREAKPOINTS.TABLET} {
    margin-bottom: 24px;
  }

  @media ${BREAKPOINTS.MOBILE} {
    margin-bottom: 16px;
  }
`;

const FeatureImage = styled(Img)`
  margin: 0 10px;
  width: 324px;
  flex-shrink: 0;

  @media ${BREAKPOINTS.TABLET} {
    width: 246px;
  }

  @media ${BREAKPOINTS.MOBILE} {
    width: 158px;
    margin: 0 6px;
  }
`;

const FeatureImages = () => {
  const data = useStaticQuery(graphql`
    query {
      f1: file(relativePath: { eq: "pages/services/feature-image-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 324) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      f2: file(relativePath: { eq: "pages/services/feature-image-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 324) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      f3: file(relativePath: { eq: "pages/services/feature-image-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 324) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      f4: file(relativePath: { eq: "pages/services/feature-image-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 324) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      f5: file(relativePath: { eq: "pages/services/feature-image-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 324) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      f6: file(relativePath: { eq: "pages/services/feature-image-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 324) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Container>
      <FeatureImage fluid={data.f1.childImageSharp.fluid} />
      <FeatureImage fluid={data.f2.childImageSharp.fluid} />
      <FeatureImage fluid={data.f3.childImageSharp.fluid} />
      <FeatureImage fluid={data.f4.childImageSharp.fluid} />
      <FeatureImage fluid={data.f5.childImageSharp.fluid} />
      <FeatureImage fluid={data.f6.childImageSharp.fluid} />
    </Container>
  );
};

export default FeatureImages;
